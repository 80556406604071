import React from 'react';
import styled from 'styled-components';
import Spinner from 'react-bootstrap/Spinner';

const SpinnerWrapper = styled.div`
  margin: auto;
  width: 100%;
  padding: 32px;
  text-align: center;
`;

const DefaultSpinner = () => (
  <SpinnerWrapper>
    <Spinner animation="grow" role="status" variant="info"></Spinner>
  </SpinnerWrapper>
);

export default DefaultSpinner;
