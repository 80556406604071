import axios from 'axios';

const API_OL = process.env.API_OL;
const PREF_FORM_OPTIONS = `${API_OL}/auth/ui/pref/options`;
const PREF_LOGIN_PRELOAD = `${API_OL}/auth/ui/prefLogin`;
const PROFILE_REQUEST = `${API_OL}/auth/ui/profile/request`;
const LOGIN_NOTICE = `${API_OL}/auth/ui/static`;

// this fetches the job role firm type plus practice area, and circuit json if condition met
const fetchAccountFormOptions = async () => {
  const queryString = window.location.search;
  let returnValue;
  try {
    const response = await axios({
      method: 'get',
      url: `${PREF_FORM_OPTIONS}${queryString}`,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    if (response.status === 200) {
      returnValue = response.data;
    }
  } catch (error) {
    // no op
  }
  return returnValue;
};

const getLoginNotice = async () => {
  let returnValue = null;
  try {
    const response = await axios({
      method: 'get',
      url: `${LOGIN_NOTICE}`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200 && response?.data?.statics) {
      const uxNotice = JSON.parse(response.data.statics.uxNotice);
      if (uxNotice.timeStartStr && uxNotice.timeEndStr && uxNotice.message) {
        returnValue = uxNotice;
      }
    }
  } catch (error) {
    // console.log('error', error);
  }
  return returnValue;
};

const prefLoginPreload = async () => {
  const queryString = window.location.search;
  let returnValue;
  try {
    const response = await axios({
      method: 'get',
      url: `${PREF_LOGIN_PRELOAD}${queryString}`,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    returnValue = response;
  } catch (error) {
    return error.response || null;
  }
  return returnValue;
};

const postProfileRequest = async (payload) => {
  let returnValue;
  const queryString = window.location.search;
  try {
    const response = await axios({
      method: 'post',
      url: `${PROFILE_REQUEST}${queryString}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: payload,
      withCredentials: true,
    });
    returnValue = response;
  } catch (error) {
    return error.response || null;
  }
  return returnValue;
};

export {fetchAccountFormOptions, prefLoginPreload, postProfileRequest, getLoginNotice};
