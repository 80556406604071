import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {defineCustomElements} from '@web-standards/moonfish-components/dist/esm/loader';
import '@web-standards/moonfish-styles/dist/themes/fish-tank.css';
import '@web-standards/moonfish-styles/dist/themes/crescent.css';
import '@web-standards/moonfish-styles/dist/index.css';
import {getLoginNotice} from '../services/prefService';

defineCustomElements();

const BannerWrapper = styled.div`
  margin: auto;
  width: 100%;
  padding: 0;
  text-align: center;
  background-color: #f0f0f0;
`;

const InfoBanner = () => {
  const [infoMsg, setInfoMsg] = useState(
    'Please note our site will be unavailable for scheduled maintenance on Sunday, February 5th from approximately 4 AM to 5 AM EST.'
  );

  const [showTimeStart, setShowTimeStart] = useState(new Date('2023-01-30 00:00:00 EST').getTime());
  const [showTimeEnd, setShowTimeEnd] = useState(new Date('2023-02-06 00:00:00 EST').getTime());

  const showBanner = () => {
    const timeNow = Date.now();
    return timeNow > showTimeStart && timeNow < showTimeEnd;
  };

  const fetchData = async () => {
    const loginNotice = await getLoginNotice();
    if (loginNotice) {
      setInfoMsg(loginNotice.message);
      setShowTimeStart(new Date(loginNotice.timeStartStr).getTime());
      setShowTimeEnd(new Date(loginNotice.timeEndStr).getTime());
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {showBanner() ? (
        <BannerWrapper>
          <m-banner dismissible="false">{infoMsg}</m-banner>
        </BannerWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default InfoBanner;
